<template>
    <ul class="nav nav-tabs nav-fill custom-tabs">
        <li class="nav-item" v-for="(link, i) in links" :key="i">
            <router-link
                :to="link.path"
                class="nav-link d-flex gap-1 align-items-center"
                :class="{ active: link.path === $route.path }"
                >{{ $t(link.label) }}
                <i
                    class="bx bx-loader text-white bx-spin fs-5"
                    v-if="profile.routeTo == link.path && profile.loading"></i>
            </router-link>
        </li>
    </ul>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            links: [
                {
                    label: 'approving agents',
                    path: '/agents-approval',
                },
                // {
                //     label: 'agent details',
                //     path: '/agents-detailed',
                // },
                {
                    label: 'agent list (all)',
                    path: '/agents/all',
                },
                // {
                //     label: 'agent list (level 1)',
                //     path: '/agents/level-1',
                // },
                // {
                //     label: 'agent list (level 2)',
                //     path: '/agents/level-2',
                // },
                // {
                //     label: 'agent list (level 3)',
                //     path: '/agents/level-3',
                // },
                {
                    label: 'agent list (level 4)',
                    path: '/agents/level-4',
                },
                {
                    label: 'agent list (level 5)',
                    path: '/agents/level-5',
                },
          
                {
                    label: 'customer service',
                    path: '/tickets/agent',
                },
            ],
        };
    },
    computed: {
        ...mapGetters('auth', ['profile']),
    },
};
</script>
